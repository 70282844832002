import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {toStyleObj, withPrefix, htmlToReact} from '../utils';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;


export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "space_mission"
    };    
    this.changeText = this.changeText.bind(this);
  }

  changeText(event) {
    this.setState({
    name: event.target.getAttribute('id')
    }); 
  }
    render() { 
      let page = this.props.pageContext.frontmatter;
      console.log(page) 
        return (
            <Layout {...this.props}>
              <article className="post post-full page_sect mission_support">
                <header className="page-header has-gradient outer">
                  {_.get(this.props, 'pageContext.frontmatter.image', null) && (
                  <div className="bg-img" style={toStyleObj('background-image: url(\'' + withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null)) + '\')')}/>
                  )}
                  <div className="inner-sm">
                    <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                    {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                    <div className="post-subtitle">
                      {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                    </div>
                    )}
                  </div>
                </header>

                <a className="go_back" href="../#capability_grid">
                  Back
                </a>
                {/* <div className="tab_menu">
                    <div className="header_buttons">
                          <button className="active">Space &amp; Mission Support </button>
                          <button>Research &amp; Development </button>
                          <button>Mission IT Services </button><button>Physical Security Services </button>
                          <button>Science Research Based Mission Services </button>
                    </div>
                </div> */}
                <div className="tab_menu">
                      {_.get(page, 'menus', null) && (
                        <div className="header_buttons">
                        {_.map(_.get(page, 'menus', null), (item, item_idx) => (
                             <div className="tab_btn">
                              {_.get(item, 'label', null) && (
                                <button id={_.get(item, 'id', null) } data-curent={ item.id } onClick={this.changeText} className={ item.id == this.state.name   ? 'active' : 'hide'} >
                                  {(_.get(item, 'label', null))}
                                </button>
                              )}
                             </div>
                        ))}
                        </div>
                      )}
                  </div>
                <div className="inner outer">
                  <div className="page-content">
                    <span className="inner_sect_ball1"></span>
                    <span className="inner_sect_ball2"></span>
                    <span className="inner_sect_ball3"></span>

                    {htmlToReact(_.get(this.props, 'pageContext.html', null))}

                    <div className="section tab_cb"  data-target="space_mission"  data-active={this.state.name == 'space_mission' ? 'active' :''}>
                      <div className="sub_header text-center">
                        {_.get(page, 'section.title', null) && (
                        <h3 className="section_title">
                          {(_.get(page, 'section.title', null))}
                        </h3>
                        )}

                        {_.get(page, 'section.description', null) && (
                          <div className="punch_dialogue">
                            <p className="description fw-600 color_blue">
                              {(_.get(page, 'section.description', null))}
                            </p>
                          </div>
                        )}
                        {_.get(page, 'section.description2', null) && (
                          <p className="description ">
                            {(_.get(page, 'section.description2', null))}
                          </p>
                        )} 
                      </div>

                        
                        <h2 className="sub_title">Space Support</h2>
                        {_.get(page, 'section.grid', null) && (
                          <div className="grid ">
                            {_.map(_.get(page, 'section.grid', null), (item, item_idx) => (
                              // console.log(item),
                              <div key={item_idx} className="grid-item card-cust">
                                <div className="box">

                                  {_.get(item, 'title', null) && (
                                  <h3 className="grid-item-title line-left">
                                      {_.get(item, 'title', null)}
                                  </h3>
                                  )}
                                  <div className="grid_cust">
                                      {_.get(item, 'img', null) && (
                                      <div className="grid-item-image">
                                        <img src={withPrefix(_.get(item, 'img', null))} alt={_.get(item, 'image_alt', null)} />
                                      </div>
                                      )}

                                      {_.get(item, 'description', null) && (
                                      <div className="box-content">
                                        {htmlToReact(_.get(item, 'description', null))}
                                      </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              ))}
                          </div>
                        )}


                        <h2  className="sub_title">Misson Support</h2>
                        <div className="support">
                          {_.get(page, 'section.mission-support', null) && (
                            <div className="mission-support">
                                {_.get(page, 'section.mission-support.title', null) && (
                                  <h4 className="grid-item-title line-left">{_.get(page, 'section.mission-support.title', null) }</h4>
                                )}
                                {_.get(page, 'section.mission-support.description', null) && (
                                  <p className="descp">{_.get(page, 'section.mission-support.description', null) }</p>
                                )}

                                
                                {_.get(page, 'section.mission-support.grid-items', null) && (
                                  <div className="grid grid-col-3">
                                    {_.map(_.get(page, 'section.mission-support.grid-items', null), (item, item_idx) => (
                                      // console.log(item),
                                      <div key={item_idx} className="grid-item ">
                                        <div className="box">

                                          <div className="grid_cust">
                                              {_.get(item, 'img', null) && (
                                              <div className="grid-item-image">
                                                <img src={withPrefix(_.get(item, 'img', null))} alt={_.get(item, 'image_alt', null)} />
                                              </div>
                                              )}
                                          </div>
                                          {_.get(item, 'title', null) && (
                                          <h3 className="grid-item-title ">
                                              {_.get(item, 'title', null)}
                                          </h3>
                                          )}
                                        </div>
                                      </div>
                                      ))}
                                  </div>
                                )}

                            </div>
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                      


                      {_.get(page, 'tab-section', null) && (
                        <div className="tab-content">
                          {_.map(_.get(page, 'tab-section', null), (item, item_idx) => (
                            console.log(item),
                            <div className="section tab_cb" data-target={_.get(item, 'section.id', null) }  data-active={this.state.name == _.get(item, 'section.id', null) ? 'active' : _.get(item, 'section.id', null)}>
                                <div className="sub_header text-center">
                                  {_.get(item, 'section.title', null) && (
                                      <h3  className="section_title">{_.get(item, 'section.title', null) }</h3>
                                  )}
                                  {_.get(item, 'section.description', null) && (
                                    <div className="punch_dialogue">
                                      <p className="description fw-600 color_blue">{_.get(item, 'section.description', null) }</p>
                                    </div>
                                  )}
                                </div>
                                {_.get(item, 'section.description2', null) && (
                                  <div className="">
                                    <p className="descp">{_.get(item, 'section.description2', null) }</p>
                                  </div>
                                )}

                                {/* list */}
                                {_.get(item, 'section.list-items', null) && ( 
                                  <div className="list_wrapper">
                                    <ul>
                                      {_.map(_.get(item, 'section.list-items', null), (item, item_idx) => (
                                      console.log(item),
                                          <li> {_.get(item, 'item',  null) }</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {_.get(item, 'section.grid-items', null) && (
                                  <div className="grid">
                                    {_.map(_.get(item, 'section.grid-items', null), (data, item_idx) => (
                                      console.log(data),
                                      <div key={item_idx} className="grid-item ">
                                        <div className="box">
                                            {_.get(data, 'title', null) && (
                                            <h3 className="grid-item-title line-left">
                                                {_.get(data, 'title', null)}
                                            </h3>
                                            )}

                                          <div className="grid_cust">
                                              {_.get(data, 'img', null) && (
                                              <div className="grid-item-image">
                                                <img src={withPrefix(_.get(data, 'img', null))} alt={_.get(data, 'image_alt', null)} />
                                              </div>
                                              )}

                                              {_.get(data, 'content', null) && (
                                              <div className="box-content">
                                                {htmlToReact(_.get(data, 'content', null))}
                                              </div>
                                              )}
                                          </div> 
                                        </div>
                                      </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                          ))}
                        </div>
                      )}
                      
                    </div>
                    </div>
              </article>

            </Layout>
        );
    }
}
